import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { Register } from '@app/utils/type-registry';
import {
  DirectionalLayoutInput,
  ExpansionPanelTableInput,
  ExpansionPanelTableOutput,
  isExpression,
} from '@trackback/widgets';
import { map, switchMap } from 'rxjs/operators';
import { BaseWidgetComponent } from '@app/widgets/base-widget.component';
import { DynamicWidgetDirective } from '@app/directives/dynamic-widget.directive';
import { ParsePipe } from '@app/pipes/parse.pipe';
import { MatSortModule } from '@angular/material/sort';
import { isString } from 'lodash-es';
import { of } from 'rxjs';

@Component({
  selector: 'tb-expansion-panel-table',
  templateUrl: './expansion-panel-table.component.html',
  styleUrls: ['./expansion-panel-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    CommonModule,
    MatExpansionModule,
    ParsePipe,
    DynamicWidgetDirective,
    MatSortModule,
  ],
})
@Register('expansionpaneltable')
export class ExpansionPanelTableComponent extends BaseWidgetComponent<
  ExpansionPanelTableInput,
  ExpansionPanelTableOutput
> {
  public widgetContent$ = this.input$.pipe(
    map(
      input =>
        ({
          type: 'DirectionalLayout',
          orientation: 'vertical',
          widgets: input?.widgets ?? [],
        }) as DirectionalLayoutInput
    )
  );

  headers$ = this.input$.pipe(
    switchMap(input => {
      if (isExpression(input.tableHeader)) {
        return this.parse(input.tableHeader);
      } else {
        return of(input.tableHeader);
      }
    })
  );

  columns$ = this.input$.pipe(
    switchMap(input => {
      if (isExpression(input.tableColumn)) {
        return this.parse(input.tableColumn);
      } else {
        return of(input.tableColumn);
      }
    })
  );
  protected readonly isString = isString;
}
